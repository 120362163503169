<template>
	<div id="addArticle">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item :to="'/classMien?id='+class_id">我的班级</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">{{id?'编辑班级风采':'添加班级风采'}}</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						标题
					</div>
					<el-input v-model="title" placeholder="请输入标题" style="width: 85%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">
						<span>*</span>
						简介
					</div>
					<el-input v-model="problem" type="textarea" placeholder="请输入简介" rows="5" style="width: 85%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">
						<span>*</span>
						文章内容
					</div>
					<div id="div1" style="width: 85%;"></div>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;"><span></span></div>
					<div class="u-f-item">
						<el-button @click="back">返回</el-button>
						<el-button type="primary" @click="submit">保存</el-button>
					</div>
				</div>
			</div>
			
			<el-dialog :visible.sync="dialogVisible">
			  <img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import E from 'wangeditor';
export default {
	components: {
		index
	},
	data() {
		return {
			title: '',
			problem: '',
			pic: '',
			cnt: '',
			dialogImageUrl: '',
			dialogVisible: false,
			id:'',
			fileList:[],
			class_id:''
		};
	},
	mounted() {
		let _this = this;
		if(_this.$route.query.id){
			_this.id = _this.$route.query.id;
		}
		_this.class_id = _this.$route.query.class_id;
		const editor = new E('#div1');
		editor.config.zIndex = 1;
		// 配置 server 接口地址
		editor.config.uploadVideoServer = '/api/upload-video';
		editor.config.uploadImgServer = 'http://school.blzzxx.cn/v1/school_api/pic/upload_arr';
		editor.config.uploadFileName = 'file';
		editor.config.height = 500
		editor.config.onchange = function (newHtml) {
			_this.cnt = newHtml;
		};
		editor.create();
		if(_this.id){
			_this.$api.setting.classMienDetails({id:_this.id}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data;
					_this.title = data.title;
					_this.problem = data.problem;
					_this.cnt = data.cnt;
					editor.txt.html(data.cnt)
				}
			})
		}
	},
	methods: {
		handleRemove(file, fileList) {	
			this.pic = '';
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		onSuccess(response,file,fileList){
			if(response.code==1){
				this.pic = response.data.url;
			}else{
				this.$message.error(response.msg)
			}
		},
		onExceed(files, fileList){
			
		},
		submit(){
			if(this.title==''||!this.title){return this.$message.error("请输入标题")}
			if(this.problem==''||!this.problem){return this.$message.error("请输入简介")}
			if(this.cnt==''||!this.cnt){return this.$message.error("请输入内容")}
			let data = {
				title:this.title,
				cnt:this.cnt,
				problem:this.problem,
				class_id:this.class_id
			}
			if(this.id){
				data.id = this.id
				this.$api.setting.editClassMien(data).then(res=>{
					if(res.data.code==1){
						this.$message.success('提交成功');
						this.$router.push({path:'/classMien',query:{id: this.class_id}})
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}else{
				this.$api.setting.addClassMien(data).then(res=>{
					if(res.data.code==1){
						this.$message.success('提交成功');
						this.$router.push({path:'/classMien',query:{id: this.class_id}})
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
			
		},
		back(){
			this.$router.push({path:'/classMien',query:{id: this.class_id}})
		}
	}
};
</script>

<style lang="scss">
#addArticle {
	.content {
		background-color: #ffffff;
		height: 83vh;
		margin-top: 15px;
		padding: 15px 0;
		overflow-y: auto;
		.input-box {
			padding-left: 15px;
			margin-bottom: 15px;
			.input-lebal {
				width: 150px;
				text-align: right;
				color: #555;
				margin-right: 10px;
				span {
					color: #ff7a65;
					margin-right: 3px;
				}
			}
			.el-upload-list__item-thumbnail{
				object-fit: cover;
			}
		}
	}
}
</style>
